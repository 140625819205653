/**
 *    Copyright 2022 Beaufort 8 GmbH

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.

 */

import { tns } from 'tiny-slider'

addEventListener('DOMContentLoaded', event => {
	'use strict'

	if (document.querySelector('.grid-container.slider-mobile') !== null) {
		// Make the whole card clickable
		let gridCols = document.querySelectorAll('.grid-container.slider-mobile .wrapper .item')

		for (let ct = 0; ct < gridCols.length; ct++) {
			const col = gridCols[ct]

			if (col.getAttribute('data-nojslink') !== null && col.getAttribute('data-nojslink') !== '1') {
				col.addEventListener('click', e => {
					if (e.currentTarget.querySelector('a') !== null) {
						let href = e.currentTarget.querySelector('a').getAttribute('href')
						window.location.href = href
					}
				})
			}
		}

		var sliderElements = document.querySelectorAll('.grid-container.slider-mobile .wrapper')
		var sliders = []
		var breakpoint = 576

		var isMobile = false

		var initsliders = function () {
			sliderElements = document.querySelectorAll('.grid-container.slider-mobile .wrapper')

			for (let s = 0; s < sliderElements.length; s++) {
				const col = sliderElements[s]

				// We need to eagerly load the slider image because lazyloading doesn't play well with tns
				col.querySelectorAll('img').forEach(img => {
					if (img.classList.contains('lazyload')) {
						img.classList.remove('lazyload')
						img.srcset = img.getAttribute('data-srcset')
						img.src = img.getAttribute('data-src')
					}
				})

				let slider = tns({
					container: col,
					items: 1,
					lazyload: false,
					controls: false,
					autoplay: false,
					speed: 400,
					slideBy: 'page',
					touch: true,
					mouseDrag: true,
					arrowKeys: false,
					gutter: 16,
					navPosition: 'bottom',
					lazyloadClass: 'tns-lazy-img',

					onInit: function (info) {
						for (let i = 0; i < info.navItems.length; i++) {
							info.navItems[i].setAttribute('tabindex', 0)
						}
					},
				})

				var removeTabIndex = function (info) {
					// direct access to info object
					for (let i = 0; i < info.navItems.length; i++) {
						info.navItems[i].setAttribute('tabindex', 0)
					}
				}

				// bind function to event
				slider.events.on('indexChanged', removeTabIndex)

				sliders.push(slider)
			}
		}

		var destroyGridSliders = function () {
			if (sliders.length > 0) {
				for (let ts = 0; ts < sliders.length; ts++) {
					const slider = sliders[ts]

					slider.destroy()
				}

				sliders = []
			}
		}

		var resizeGrid = function () {
			// This gets delayed for performance reasons

			if (window.innerWidth >= breakpoint) {
				if (isMobile) {
					isMobile = false
					destroyGridSliders()
				}
			} else {
				if (isMobile == false) {
					isMobile = true
					initsliders()
				}
			}
		}

		if (sliderElements) {
			if (window.innerWidth >= breakpoint) {
				isMobile = false
			} else {
				isMobile = true
				initsliders()
			}

			window.addEventListener('resize', function () {
				requestAnimationFrame(resizeGrid)
			})
		}

		/*
        Currently not used
        // Show more button

        $(".show-articles-btn").click(function(event) {
          $(this).siblings(".grid-container").removeClass("hidden-articles");
          var y = $(this)
          .siblings(".grid-container")
          .find(".teaser:eq(6)")
          .offset(); //get offset of 6th element (first hidden element)
          $(this).remove();

          $("html, body").animate(
              {
                scrollTop: y.top - 100, //scroll smoothly to 6th element
              },
              500
          );
        });

        // Remove show more button on "desktop"
        if (window.innerWidth >= 800)
        {
          $(".show-articles-btn")
          .siblings(".grid-container")
          .removeClass("hidden-articles");
          $(".show-articles-btn").remove();
        }

         */
	}
})
